<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">My SSH authentication setttings</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-5" v-if="user">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Username</p>
                    <p class="mb-0 pb-0" v-if="user.ssh_alias">
                        {{ user.ssh_alias }}
                        <!-- <v-btn class="blue--text no-print" @click="dialogEditSshAlias = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn> -->
                    </p>
                    <template v-if="!user.ssh_alias">
                        <p class="mb-0 pb-0">
                            You don't have an SSH username yet.
                        </p>
                        <p class="mb-0 pb-0">
                            <v-btn color="purple" class="white--text" @click="dialogEditSshAlias = true">Create</v-btn>
                        </p>
                    </template>
                    <!-- <v-progress-circular indeterminate color="purple" :width="3" :size="20"></v-progress-circular> -->
                    <v-dialog v-model="dialogEditSshAlias" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Edit SSH username</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <template v-if="user.ssh_alias">
                                            <p class="font-weight-bold">You already have an SSH username, but you can change it here.</p>
                                        </template>
                                        <p>You can customize the first part of the username.</p>
                                        <p>When you save, we will automatically add some random numbers at the end.</p>
                                        <!-- <p>It must start with a letter. After that, only numbers, letters, and underscores</p> -->
                                        <!-- <p>For example, "Sparky".</p> -->
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form @submit="editSshAlias" onSubmit="return false;" @keyup.enter.native="editSshAlias">
                                <v-text-field
                                    v-model=editableSshAlias
                                    ref="sshAliasInput"
                                    label="SSH username"
                                    :rules="sshAliasRules"
                                    outlined
                                ></v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="editSshAlias" :disabled="!isEditSshAliasFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSshAlias = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <template v-if="user.ssh_alias">
                        <!-- NOTE: temporarily disabling passwords until we verify that it's working, when this is deployed in production -->
                    <!-- <p class="text-overline mb-0 mt-8">Password</p>
                    <p class="mb-0 pb-0">
                        <template v-if="user.ssh_enable_password">
                            <font-awesome-icon :icon="['fas', 'check']" color="green" fixed-width size="1x"/>
                            Password login is enabled
                        </template>
                        <template v-if="!user.ssh_enable_password">
                            Password login is disabled
                        </template>
                        <v-btn class="blue--text no-print" @click="dialogEditSshPassword = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <p class="mb-0 pb-0 text-caption" v-if="user.ssh_enable_password">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" color="orange" fixed-width size="1x"/>
                        Passwords are less secure than public key authentication. Consider disabling the password and using a public key instead.
                    </p> -->
                    <v-dialog v-model="dialogEditSshPassword" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Manage SSH password</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <template v-if="user.ssh_enable_password">
                                            <p class="font-weight-bold">You already have an SSH password. You can change it here.</p>
                                        </template>
                                        <template v-if="!user.ssh_enable_password">
                                            <p>Enter a password or use the <span class="font-weight-bold">Generate</span> button to make a strong password.</p>
                                        </template>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form @submit="editSshPassword" onSubmit="return false;" @keyup.enter.native="editSshPassword">
                                <v-checkbox v-model="editableSshPasswordEnabled" label="Use a password to login"/>
                                <v-text-field
                                    v-model="editableSshPassword"
                                    ref="sshPasswordInput"
                                    label="SSH password"
                                    :rules="sshPasswordRules"
                                    outlined
                                    :type="editableSshPasswordInputType"
                                    :loading="editableSshPasswordScoreVisible"
                                    :disabled="!editableSshPasswordEnabled"
                                >
                                    <template #append>
                                        <v-btn text color="indigo" @click="generateNewPassword">Generate</v-btn>
                                        <v-btn icon @click="editableSshPasswordVisible = !editableSshPasswordVisible" :color="editableSshPasswordVisible ? 'blue darken-1' : 'grey darken-1'" class="ma-0 pa-0">
                                            <font-awesome-icon :icon="['fas', 'eye']"/>
                                        </v-btn>
                                    </template>
                                    <template #progress>
                                        <v-progress-linear v-model="editableSshPasswordScorePercent" :color="editableSshPasswordScoreColor" absolute rounded class="mt-1" height="12">
                                            <span style="font-size: 0.7em" :style="{ color: editableSshPasswordScore === 4 ? 'white' : 'black' }">{{ editableSshPasswordScoreText }}</span>
                                        </v-progress-linear>
                                    </template>
                                </v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="editSshPassword" :disabled="!isEditSshPasswordFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSshPassword = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <p class="text-overline mb-0 mt-8">Public Key</p>
                    <p class="mb-0 pb-0">
                        <template v-if="user.ssh_enable_publickey">
                            <font-awesome-icon :icon="['fas', 'check']" color="green" fixed-width size="1x"/>
                            Public key login is enabled
                        </template>
                        <template v-if="!user.ssh_enable_publickey">
                            <!-- <font-awesome-icon :icon="['fas', 'exclamation-triangle']" color="orange" fixed-width size="1x"/> -->
                            Public key login is disabled
                        </template>
                        <v-btn class="blue--text no-print" @click="dialogEditSshPublicKey = true" icon>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                    </p>
                    <v-dialog v-model="dialogEditSshPublicKey" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Reset SSH public key</v-card-text>
                            <v-row justify="center" class="pt-3">
                                <v-col cols="12">
                                    <div class="font-weight-light text-body-1">
                                        <template v-if="user.ssh_enable_publickey">
                                            <p class="font-weight-bold">You already have an SSH public key. You can reset it here.</p>
                                        </template>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-form @submit="editSshPublicKey" onSubmit="return false;" @keyup.enter.native="editSshPublicKey">
                                <v-checkbox v-model="editableSshPublicKeyEnabled" label="Use a public key to login"/>
                                <v-text-field
                                    v-model="editableSshPublicKey"
                                    ref="sshPublicKeyInput"
                                    label="SSH public key"
                                    :rules="sshPublicKeyRules"
                                    outlined
                                    :disabled="!editableSshPublicKeyEnabled"
                                ></v-text-field>
                            </v-form>
                            <v-card-text v-ifdev>
                                <p>editableSshPublicKey: {{ JSON.stringify(editableSshPublicKey) }}</p>
                                <p>editableSshPublicKeyEnabled: {{ JSON.stringify(editableSshPublicKeyEnabled) }}</p>
                                <p>user ssh authorized keys: {{ JSON.stringify(user.ssh_authorized_keys) }}</p>
                                <p>user ssh publickey enabled: {{ JSON.stringify(user.ssh_enable_publickey) }}</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue white--text" @click="editSshPublicKey" :disabled="!isEditSshPublicKeyFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSshPublicKey = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <!--
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'profile-settings-authz' }">See my roles and permits</router-link>
                    </p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'profile-settings-authn' }">Change my LibertyCloud login settings</router-link>
                    </p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'profile-settings-ssh' }">Change my SSH login settings</router-link>
                    </p>
                    <p class="mb-0 pb-0">
                        <v-btn color="purple" class="white--text" @click="refreshUserProfile">Refresh</v-btn>
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Other</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'user-check-ssh', params: { userId: user.id } }">Check SSH access</router-link>
                    </p>
                    </template>
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableSshAlias
                                color="purple"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="blue white--text" @click="editSshAlias" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="purple"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<style lang="css">
.v-input__control .v-input__append-inner {
    margin-top: 10px; /* (56 input height - 36 button height) / 2 */
    margin-bottom: 10px;
    padding: 0px;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isValidSshAliasPrefix } from '@/sdk/input';
import { randomText } from '@/sdk/random';

export default {
    data: () => ({
        // ssh alias
        dialogEditSshAlias: false,
        editableSshAlias: null,
        sshAliasRules: [
            (v) => isValidSshAliasPrefix(v) || 'Enter a username (only letters)',
        ],
        // ssh password
        dialogEditSshPassword: false,
        editableSshPasswordEnabled: false,
        editableSshPassword: null,
        editableSshPasswordVisible: false,
        editableSshPasswordScore: null,
        // ssh publickey
        dialogEditSshPublicKey: false,
        editableSshPublicKeyEnabled: false,
        editableSshPublicKey: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isEditSshAliasFormComplete() {
            return this.user
            && this.editableSshAlias
            && isValidSshAliasPrefix(this.editableSshAlias);
        },
        editableSshPasswordInputType() {
            return this.editableSshPasswordVisible ? 'text' : 'password';
        },
        editableSshPasswordScoreVisible() {
            return typeof this.editableSshPassword === 'string' && this.editableSshPassword.length > 0 && typeof this.editableSshPasswordScore === 'number';
        },
        editableSshPasswordScorePercent() {
            if (typeof this.editableSshPasswordScore === 'number') {
                // translate the 0..4 score to 20%, 40%, 60%, 80%, 100%
                return ((this.editableSshPasswordScore + 1) * 100) / 5;
            }
            return 0;
        },
        editableSshPasswordScoreColor() {
            switch (this.editableSshPasswordScore) {
            case 0:
                return 'red darken-4';
            case 1:
                return 'red';
            case 2:
                return 'amber';
            case 3:
                return 'yellow';
            case 4:
                return 'green';
            default:
                return 'black';
            }
        },
        editableSshPasswordScoreText() {
            switch (this.editableSshPasswordScore) {
            case 0:
                return 'extremely weak';
            case 1:
                return 'weak';
            case 2:
                return 'weak';
            case 3:
                return 'moderate';
            case 4:
                return 'strong';
            default:
                return '';
            }
        },
        isEditSshPasswordFormComplete() {
            return (this.editableSshPasswordEnabled && this.editableSshPassword?.length > 0 && typeof this.editableSshPasswordScore === 'number' && this.editableSshPasswordScore >= 3)
            || (!this.editableSshPasswordEnabled && (this.user.ssh_enable_password ?? false));
        },
        isEditSshPublicKeyFormComplete() {
            return (this.editableSshPublicKeyEnabled && this.editableSshPublicKey?.length > 0)
            || (!this.editableSshPublicKeyEnabled && (this.user.ssh_enable_publickey ?? false));
        },
    },
    watch: {
        dialogEditSshAlias(display) {
            if (display) {
                setTimeout(() => { this.$nextTick(() => this.$refs.sshAliasInput.focus()); }, 1);
                this.editableSshAlias = this.user.alias;
            }
        },
        dialogEditSshPassword(display) {
            if (!display) {
                this.editableSshPassword = null;
                this.editableSshPasswordVisible = false;
            }
        },
        editableSshPassword(value) {
            this.checkNewPasswordQuality(value);
        },
        editableSshPasswordEnabled(value) {
            if (!value) {
                this.editableSshPassword = null;
                this.editableSshPasswordVisible = false;
            }
        },
    },
    methods: {
        init() {
            console.log('Preferences.vue: init');
        },
        async editSshAlias() {
            try {
                this.$store.commit('loading', { editSshAlias: true });
                const result = await this.$client.user(this.$store.state.session.userId).user.edit({ ssh_alias: this.editableSshAlias });
                if (result?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Updated SSH username' });
                    await this.$store.dispatch('loadUser');
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update SSH username' });
                }
            } catch (err) {
                console.error('editSshAlias failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update SSH username' });
            } finally {
                this.$store.commit('loading', { editSshAlias: false });
                this.dialogEditSshAlias = false;
            }
        },
        async editSshPassword() {
            try {
                this.$store.commit('loading', { editSshPassword: true });
                if (this.editableSshPasswordEnabled && this.editableSshPassword) {
                    // change the password
                    const result = await this.$client.user(this.$store.state.session.userId).user.edit({ ssh_password: this.editableSshPassword });
                    if (result?.isEdited) {
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Updated SSH password' });
                        await this.$store.dispatch('loadUser');
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update SSH password' });
                    }
                } else {
                    // disable password
                    const result = await this.$client.user(this.$store.state.session.userId).user.edit({ ssh_enable_password: this.editableSshPasswordEnabled });
                    if (result?.isEdited) {
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Disabled SSH password' });
                        await this.$store.dispatch('loadUser');
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to disable SSH password' });
                    }
                }
            } catch (err) {
                console.error('editSshPassword failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update SSH password' });
            } finally {
                this.$store.commit('loading', { editSshPassword: false });
                this.dialogEditSshPassword = false;
                this.editableSshPassword = null;
            }
        },
        async editSshPublicKey() {
            try {
                this.$store.commit('loading', { editSshPublicKey: true });
                if (this.editableSshPublicKeyEnabled && this.editableSshPublicKey) {
                    const result = await this.$client.user(this.$store.state.session.userId).user.edit({ ssh_publickey: this.editableSshPublicKey });
                    if (result?.isEdited) {
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Updated SSH public key' });
                        await this.$store.dispatch('loadUser');
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update SSH public key' });
                    }
                } else {
                    const result = await this.$client.user(this.$store.state.session.userId).user.edit({ ssh_enable_publickey: this.editableSshPublicKeyEnabled });
                    if (result?.isEdited) {
                        this.$bus.$emit('snackbar', { type: 'success', message: 'Changed public key setting' });
                        await this.$store.dispatch('loadUser');
                    } else {
                        this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to change SSH public key setting' });
                    }
                }
            } catch (err) {
                console.error('editSshPublicKey failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update SSH public key' });
            } finally {
                this.$store.commit('loading', { editSshPublicKey: false });
                this.dialogEditSshPublicKey = false;
            }
        },
        generateNewPassword() {
            this.editableSshPassword = randomText(24);
            this.editableSshPasswordVisible = true;
        },
        async checkNewPasswordQuality(value) {
            try {
                const result = await this.$client.main().authn.checkPasswordQuality({ password: value });
                console.log(`response: ${JSON.stringify(result)}`);
                this.editableSshPasswordScore = result?.score;
            } catch (err) {
                console.log('checkPassword failed', err);
                this.editableSshPasswordScore = null;
            }
        },
    },
    mounted() {
        this.editableSshPasswordEnabled = this.user.ssh_enable_password;
        this.editableSshPublicKeyEnabled = this.user.ssh_enable_publickey;
        this.editableSshPublicKey = this.user.ssh_authorized_keys;
    },
};
</script>
